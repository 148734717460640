<template>
  <headerComp :mode="'works'"></headerComp>
  <div class="container-full">
    <div class="row">
      <div class="col-6 d-flex fade-in2">
        <div class="works-logo-container">
          Works
        </div>
      </div>
    </div>
    <div class="works-container fade-in">
      <div class="row" v-for="(row, key) in rowCount" :key="key">
        <div class="col-md-6" v-for="(collection, key2) in itemCountInRow(row)" :key="key2">
          <div v-if="collection.fileurl && collection.fileextension == 'mp4'" class="col works-col">
            <!-- FileName -->
            <div class="video-col">
              <video class="video-works" :src="collection.fileurl" controls :poster="collection.thumburl"></video>
            </div>
            <div class="worrk-item-title">{{ collection.title }}</div>
          </div>
          <div v-else-if="collection.fileurl" class="col works-col">
            <!-- FileName -->
            <img class="img-works" :src="collection.fileurl" />
            <div class="worrk-item-title">{{ collection.title }}</div>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
</template>
  
  <script>
  import HeaderComp from '../components/HeaderComp.vue';
   export default{
     components: {
       HeaderComp
     },
     //props: ['email'],
     data() {
       return {
         datas : [],
         selData : {},
         search:'',
         filter:'',
         sort:'',
         ascdesc:'',
         page:1,
         colNumber:2,
         rowCount:0,
         currentMode:'all',
         currentEmail:''
       }
     },
     created: function() {
       const {email} = this.$route.params;
       this.currentEmail = email;
       const param = { page:this.page,search : this.search, sort : this.sort, ascdesc:this.ascdesc  };
       this.loadList(param);
     },
     methods: {
       loadList(param) {
        let me = this;
         this.$axios.post('/api/imageworks/list', param) 
         // eslint-disable-next-line no-unused-vars
           .then(function (response) {
             if(response.data.result){
              me.datas = response.data.datas;
              me.rowCount = Math.ceil(me.datas.length / me.colNumber);
             }
           })
           .catch(function (error) {
             console.log(error);
           });
           
       },
       itemCountInRow:function(row){
        let retRow = this.datas.slice((row - 1) * this.colNumber, row * this.colNumber);
        if(retRow.length < this.colNumber){
          const max = this.colNumber - retRow.length;
          for(let i = 0;i < max;i++){
            retRow.push({});
          }
        }
        return retRow;
       },
       execSearch(){
         const param = { page:this.page,search : this.search, sort : this.sort, ascdesc:this.ascdesc,filtername:this.filter};
         this.loadList(param);
       },
       clearSearch(){
         this.search = '';
       },
       onClickFilterMenu(index){
         this.filter = this.menuItems[index].value;
         const param = { page:this.page,search : this.search, sort : this.sort, ascdesc:this.ascdesc,filtername:this.filter};
         this.loadList(param);
       },
       //showDetail(id){
       // console.log(id);
       // this.$router.push({name: 'detail', params: {id: id,email:this.currentEmail}});
       //},
       //selCategory(category){
       // this.currentMode = category;
       // const param = { category:category == 'all' ? '' : category };
       // this.loadList(param);
       //}
     }
   };
  </script>