<template>
  <headerComp :mode="'home'"></headerComp>
    <div class="container-full">
      <div class="row container-row">
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <div class="logo-container fade-in2">
            JULIE HERMANT
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <div class="home-image-container fade-in">
          </div>
        </div>
      </div>
      <div class="row">
          <div class="spacer-home"></div>
        </div>
    </div>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'home',
  components: {
    HeaderComp
  },
  data () {
    return {
      lastData : {}
      }
  },
  created: function() {
    //this.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      this.$axios.post('/api/articles/last') 
      // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          if(response.data.result){
            me.data = {};
            let responseData = response.data.data;
              me.lastData = responseData;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
};
</script>