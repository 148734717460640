<template>
    <v-card>
      <v-toolbar color="#ffffff">
        <v-toolbar-title>{{ r_title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
          icon
          dark
          @click="execEmit(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="d-flex justify-content-center col dialog-message">{{ r_message }}</div>
          </div>
        </v-container>
      </v-card-text>
      <!--
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
        class="text-none"
        variant="outlined"
          @click="execOperation(false)"
        >
        Oui
        </v-btn>
        <v-btn
          class="text-none"
          variant="outlined"
            @click="execEmit(false)"
          >
          Non
        </v-btn>
      </v-card-actions>
      -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="text-none"
          variant="outlined"
            @click="execEmit(false)"
          >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
    
</template>
<script>
  import { ref, defineComponent } from 'vue'
//  import axios from "axios"
  export default defineComponent({
    props: {
        title:{
            type:String
        },
        message:{
            type:String
        }
    },
    data(){
      return{
        //isEdit:false,
        //mode:''
       
      }
    },
    //emits: ['eventEmit','operation'],
    emits: ['eventEmit'],
    setup(props, context) {
        //const open = ref(false);
        const r_title = ref(props.title);
        const r_message = ref(props.message);
      const execEmit = (isOpen) => {
        context.emit('eventEmit', { 'result': true, 'openDialog':isOpen})
      }
      //const execOperation = (isOpen) => {
      //  context.emit('operation', { 'result': true, 'openDialog':isOpen})
      //  
      //}        
      return {
        r_title,
        r_message,
        execEmit,
        //execOperation
      }
    },
    methods :{
    }
  })
  </script>