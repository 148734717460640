<template>
    <div class="header-container">
        <div class="d-flex">
            <nav class="d-flex nav-container">
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'home'}">
                <router-link to="/">Home</router-link>
                </div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'works'}">
                <router-link to="/works">Works</router-link>
                </div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'collection'}">
                <a href="/pr/">Collection</a>
                </div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'about'}">
                <router-link to="/about">About</router-link>
                </div>
                <div class="header-menu" v-bind:class="{'header-menu-current':currentMode == 'contact'}">
                <router-link to="/contact">Contact</router-link>
                </div>
            </nav>
            <div v-if="currentMode != 'home'" class="menu-logo-container d-flex align-items-end justify-content-end">
            <div class="menu-logo">JULIE HERMANT</div>
            </div>
        </div>
    </div>
    <div class="header-container-sp">
        <v-toolbar-title v-if="currentMode != 'home'" class="menu-logo-sp">JULIE HERMANT</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu>
            <template v-slot:activator="{ props }">
              <v-app-bar-nav-icon variant="text" v-bind="props" style="font-size: 1.5em;"></v-app-bar-nav-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in menus"
                :key="index"
                :value="index"
              >                
                <v-list-item-title @click="onClickMenu(index)">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
    </div>
</template>

<script>
  import { ref, defineComponent } from 'vue'
  export default defineComponent({
    props: {
        mode:{
            type:String
        }
       
    },
    setup(props) {
        const currentMode = ref(props.mode);
        return {
            currentMode
        }
    },
    data(){
      return{
        menus: [
          { 
            title: 'Home',
            props: {
              prependIcon: 'mdi-email-multiple',
            }
            ,url:'/'
          },
          {
            title: 'Works',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/works'
          },
          {
            title: 'Collection',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/pr/'
          },
          {
            title: 'About',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/about'
          },
          {
            title: 'Contact',
            props: {
              prependIcon: 'mdi-account-multiple',
            }
            ,url:'/contact'
          }
        ],        
      }
    },
    methods :{
        onClickMenu(index){
            let url = this.menus[index].url;
            if(this.menus[index].title == 'Collection'){
              document.location.href = url;
            }else{
              this.$router.push(url);
            }
        }
    }
  })
  </script>