<template>
  <headerComp :mode="'contact'"></headerComp>
    <div class="container-full">
      <div class="d-flex justify-content-center align-items-center row container-row">
<!--          <div class="col-md-6">-->
          <div class="d-flex justify-content-center alignitems-center">
            <div>
            <div class="contact-logo-container fade-in">
              Contact
            </div>
            <div class="contact-container fade-in2">
                Free feel to contact me, I'll be happy to work on your projects.<br/><br/>
                juliehermant@gmail.com<br/>
                +33(0)6 62 32 54 76<br/><br/>
                <v-icon>mdi-alert-box-outline</v-icon>Please check to see if my reply is on spam
              </div>
            </div>
          </div>

          <!--
          <div class="col-md-6 d-flex">
            <div class="container">
              <div class="contact-form-container fade-in3">
                <v-form ref="form">
                      <div class="row justify-content-md-center">              
                        <div class="col-md-8">
                          <v-text-field
                          ref="name"
                          label="Your name*" 
                          variant="outlined" 
                          v-model="name"
                          :rules="nameRules"
                          clearable
                          required
                          ></v-text-field>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">              
                        <div class="col-md-8">
                          <v-text-field 
                          type="email"
                          label="Your e-mail*" 
                          variant="outlined" 
                          v-model="email"
                          :rules="emailRules"
                          clearable
                          required
                          ></v-text-field>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">              
                        <div class="col-md-8">
                          <v-text-field label="Company Name" variant="outlined" v-model="company"></v-text-field>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">              
                        <div class="col-md-8">
                          <v-text-field 
                          label="Subject*" 
                          variant="outlined" 
                          v-model="subject"
                          :rules="subjectRules"
                          clearable
                          required
                          ></v-text-field>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">              
                        <div class="col-md-8">
                          <v-textarea 
                          label="Message*" 
                          variant="outlined" 
                          v-model="body"
                          :rules="bodyRules"
                          clearable
                          required
                          ></v-textarea>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-md-8">
                          <v-btn @click="post">Send</v-btn>
                        </div>
                      </div>
                  </v-form>
                </div>
              </div>
          </div>
        -->
        </div>
        <!--
        <div class="row">
          <div class="spacer"></div>
        </div>
      -->
    </div>
    <div class="text-center">
        <v-dialog
          v-model="openCommonDlg"
          persistent
          width="90%"
          height="90%"
        >
          <commonDialog :title="dlgTitle" :message="dlgMessage" @eventEmit="execEmitOpenCommonDlg">
          </commonDialog>
        </v-dialog>
    </div>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
import CommonDialog from '../components/CommonDialog.vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'contact',
  components: {
       HeaderComp,
       CommonDialog
     },
  data () {
    return {
      msg : '',
      name : null,
      email : null,
      company : null,
      phone : null,
      address : null,
      subject : null,
      body : null,
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 255) || 'Name must be less than 255 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => (v && v.length <= 255) || 'E-mail must be less than 255 characters',
      ],
      subjectRules: [
        v => !!v || 'Subject is required',
        v => (v && v.length <= 255) || 'Subject must be less than 255 characters',
      ],
      bodyRules: [
        v => !!v || 'Message is required'
      ],
      valid: true,
      dlgMessage:'',
      dlgTitle:'',
      openCommonDlg:false
    }
  },
  methods: {
    async post() {
      const { valid } = await this.$refs.form.validate()

      if (!valid){return}
      
      const data = {
        name:this.name,
        email : this.email,
        company : this.company,
        phone : this.phone,
        address : this.address,
        subject : this.subject,
        body : this.body
     };
      this.msg = await this.$axios.post('/api/contacts/add', data)
      // eslint-disable-next-line no-unused-vars
      .then(function (response) {
        return 'OK';
      })
      .catch(function (error) {
        return error.message;
      });
      
      if(this.msg == 'OK'){
          this.dlgTitle = "";
          this.dlgMessage = "Thank you for your message.";
          this.execOpenCommonDlg();
      }else{
          this.dlgTitle = "Error";
          this.dlgMessage = "Please retry after few hours." ;
          this.execOpenCommonDlg();
      }
    },
    execOpenCommonDlg(){            
        this.openCommonDlg = true;
    },
    execEmitOpenCommonDlg(){
        this.name = null;
        this.email = null;
        this.company = null;
        this.phone = null;
        this.address = null;
        this.subject = null;
        this.body = null;
        this.openCommonDlg = false;
    }
  }
};
</script>