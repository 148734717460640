import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Works from '../views/WorkImages.vue'
import Reply from '../views/Reply.vue'
import Store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/works',
    name: 'works',
    component: Works
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  }
  ,
  {
    path: '/reply:email/:subject',
    name: 'reply',
    component: Reply,
    props: route => ({email: route.params.email, subject:route.params.subject}),
  }
]

const router = createRouter({
//  history: createWebHashHistory(),
  history: createWebHistory(),
  //base: process.env.BASE_URL,
  routes
})
/*
const router = createRouter({
  history: createWebHistory('/pb/'),
  routes
})
*/

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Store.state.isLogin) {
      // 認証されていない時、認証画面へ遷移
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next();
    }
  } else {
    next(); 
  }
});

export default router
