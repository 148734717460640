<template>
    <headerComp :mode="'contact'"></headerComp>
      <div class="container-full">
        <div class="row container-row">
            <div class="col-md-6">
              <div class="contact-logo-container fade-in">
                Reply
              </div>
              <div class="contact-container fade-in2">
                  Plase enter the following items andclick the SEND button.
                  Please be sure to enter items marked with *.<br/>
                  Thank you.
                </div>
            </div>
            <div class="col-md-6 d-flex">
              <div class="container">
                <div class="contact-form-container fade-in3">
                  <v-form ref="form">
                      <div class="row justify-content-md-center">              
                          <div class="col-md-8">
                            <v-text-field 
                            type="email"
                            label="Your e-mail"
                            variant="outlined" 
                            v-model="currentEmail"
                            disabled
                            ></v-text-field>
                          </div>
                      </div>
                      <div class="row justify-content-md-center">              
                          <div class="col-md-8">
                            <v-text-field 
                            label="Subject" 
                            variant="outlined" 
                            v-model="currentSubject"
                            disabled
                            ></v-text-field>
                          </div>
                      </div>
                      <div class="row justify-content-md-center">              
                          <div class="col-md-8">
                            <v-textarea 
                            label="Message*" 
                            variant="outlined" 
                            v-model="body"
                            :rules="bodyRules"
                            clearable
                            required
                            ></v-textarea>
                          </div>
                      </div>
                      <div class="row justify-content-md-center">
                          <div class="col-md-8">
                            <v-btn @click="post">Send</v-btn>
                          </div>
                        </div>
                    </v-form>
                  </div>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="spacer"></div>
          </div>
      </div>
      <div class="text-center">
          <v-dialog
            v-model="openCommonDlg"
            persistent
            width="90%"
            height="90%"
          >
            <commonDialog :title="dlgTitle" :message="dlgMessage" @eventEmit="execEmitOpenCommonDlg">
            </commonDialog>
          </v-dialog>
      </div>
  </template>
  
  <script>
  import HeaderComp from '../components/HeaderComp.vue';
  import CommonDialog from '../components/CommonDialog.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'contact',
    components: {
         HeaderComp,
         CommonDialog
       },
    props: ['contact_id','email'],
    data () {
      return {
        msg : '',
        currentEmail : null,
        currentSubject : null,
        subject : null,
        body : null,
        bodyRules: [
          v => !!v || 'Message is required'
        ],
        valid: true,
        dlgMessage:'',
        dlgTitle:'',
        openCommonDlg:false
      }
    },
     created: function() {
        const {email} = this.$route.params;
        const {subject} = this.$route.params;
        this.currentEmail = email;
        this.currentSubject = 'Re:' + subject;
     },
    methods: {
      async post() {
        const { valid } = await this.$refs.form.validate()
  
        if (!valid){return}
        
        const data = {
          email : this.currentEmail,
          subject : this.currentSubject,
          body : this.body
       };
        this.msg = await this.$axios.post('/api/contacts/reply', data)
        // eslint-disable-next-line no-unused-vars
        .then(function (response) {
          return 'OK';
        })
        .catch(function (error) {
          return error.message;
        });
        
        if(this.msg == 'OK'){
            this.dlgTitle = "";
            this.dlgMessage = "Thank you for your message.";
            this.execOpenCommonDlg();
        }else{
            this.dlgTitle = "Error";
            this.dlgMessage = "Please retry after few hours." ;
            this.execOpenCommonDlg();
        }
      },
      execOpenCommonDlg(){            
          this.openCommonDlg = true;
      },
      execEmitOpenCommonDlg(){
          this.body = null;
          this.openCommonDlg = false;
      }
    }
  };
  </script>