<template>
  <headerComp :mode="'about'"></headerComp>    
    <div class="container-full">
      <div class="row container-row">
        <div class="row about-row d-flex justify-cotent-center align-items-center">
          <div class="col-md d-flex about-container align-items-center">
            <div>  
              <div class="row">
                <div class="col d-flex">
                  <div class="about-logo-container fade-in">
                    About
                  </div>
              </div>
            </div>
            <div class="about-text fade-in3">
Julie Hermant is a textile and home designer.
She presents her collection of abstract and floral patterns at international trade shows and by apppointment.
Julie Hermant's work crosses multiple universes depending on her artistic and professional encounters.
Recently she has been exploring abstract patterns in particular.
Simple shapes, polka dots and lines that she cuts, shifts, glues and colours tirelessly to create compositions.
She works instinctively with Indian ink and then finalizes her designs with digital tools.
She also works to order to suit your projects.
She lives in Marseille.<br/><br/>
Her clients: Bonton, Monoprix, Cezane, La Redoute, wolf lingerie, Galerie Lafayette, Smallable, Ikatee, Compania Fantastica, Boden, Hachette...
<br/><br/>
            </div>
          </div>
        </div>

        <!--for PC-->
        <div class="col-md about-image  d-flex justify-content-center align-items-center fade-in">
          <div class="about-image-container d-flex justify-content-center align-items-center ">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="spacer-about"></div>
    </div>
  </div>
</template>

<script>
import HeaderComp from '../components/HeaderComp.vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'about',
  components: {
       HeaderComp
     },
  data () {
    
  },
  methods: {
  }
};
</script>